import { Cookies } from 'react-cookie'
import { IAuthData } from '../types/user'
import * as jwt from 'react-jwt'
import { AssistantType } from '../types/assistant'

export class CookiesService {
    private reactCookie: Cookies

    constructor() {
        this.reactCookie = new Cookies()
    }

    getAuthData() {
        const cookie = this.reactCookie.get('AUTH_DATA')
        return cookie || {}
    }

    saveAuthData(authData: IAuthData) {
        const decoded: any = jwt.decodeToken(authData.access_token)
        const milissegundos = decoded.exp * 1000
        const EXPIRES = new Date(milissegundos)
        if (authData) {
            this.reactCookie.set('AUTH_DATA', JSON.stringify(authData), {
                expires: EXPIRES,
                path: '/',
            })
        }
    }

    removeAuthData() {
        this.reactCookie.remove('AUTH_DATA')
    }

    saveAssistant(assistant: AssistantType) {
        const SEVEN_DAYS_IN_HOURS = 168
        const EXPIRES = new Date()
        EXPIRES.setHours(EXPIRES.getHours() + SEVEN_DAYS_IN_HOURS)

        this.reactCookie.set('ASST', JSON.stringify(assistant), {
            expires: EXPIRES,
        })
    }

    getAssistant() {
        return this.reactCookie.get('ASST')
    }

    removeAssistant() {
        this.reactCookie.remove('ASST')
    }

    setCheckboxStatus() {
        // seta o checkbox de política de testes para true por 30 dias.

        const DAYS_TO_SUM = 30
        const EXPIRES = new Date()
        EXPIRES.setDate(EXPIRES.getDate() + DAYS_TO_SUM)

        this.reactCookie.set('CHECK', JSON.stringify(true), {
            expires: EXPIRES,
        })
    }
    getCheckboxStatus() {
        //checa se o checkbox de política de testes foi aceito.

        const value = this.reactCookie.get('CHECK')
        return value
    }

    saveFlag(flag: string) {
        const DAYS_TO_SUM = 7
        const EXPIRES = new Date()
        EXPIRES.setDate(EXPIRES.getDate() + DAYS_TO_SUM)

        this.reactCookie.set('FLAG', JSON.stringify(flag), {
            expires: EXPIRES,
        })
    }

    getFlag() {
        const value = this.reactCookie.get('FLAG')
        return value
    }

    removeFlag() {
        this.reactCookie.remove('FLAG')
    }
}
