import styled from 'styled-components'
import { COLORS } from '../../utils/colors'
const depoimentos = [
    {
        titulo: 'Testei a ferramenta e fiquei impressionado com a resposta. Utilizei-a para perguntar sobre um caso concreto que estamos analisando aqui na Controladoria Geral do Município. Já havíamos realizado nossa análise técnica, mas a análise do Schief foi ainda mais completa, pois apresentou uma jurisprudência do TCU aplicável ao caso que não havíamos cogitado. Parabéns aos idealizadores!',
        nome: 'Lucas Dias Noronha',
        descricao: 'Auditor de Controle Interno - Aracruz (ES)',
    },
    {
        titulo: 'A plataforma Schief.AI tem se mostrado uma grande aliada na elaboração de pareceres jurídicos, sendo certeira na indicação de entendimentos e jurisprudências aplicáveis.',
        nome: 'Cinthia Steffane Bento de Oliveira',
        descricao: 'Procuradora',
    },
    {
        titulo: 'A ferramenta é fantástica. Ela traz boas respostas, de maneira objetiva e fundamentada. Acredito que será muito útil não só para os profissionais do direito, mas também para os demais que trabalhem com licitações.',
        nome: 'Aline Simões Baldini',
        descricao: 'Procuradora do Município de Ourinhos',
    },
    {
        titulo: 'A Schief.AI é uma bênção.',
        nome: 'Vivian Telles da Silva',
        descricao: 'Prefeitura de Osório (RS)',
    },
    {
        titulo: 'A utilização do Schief.ai tem transformado nossa abordagem em licitações públicas e contratos administrativos. Essa poderosa solução de inteligência artificial generativa e Big Data não apenas otimiza nossos processos, mas também nos proporciona insights precisos e valiosos, garantindo maior eficiência e transparência. Com o Schief.ai, nossa equipe se sente mais confiante e preparada para tomar decisões estratégicas, resultando em uma gestão mais eficaz e resultados significativamente melhores.',
        nome: 'Moacir Marcelo Monção',
        descricao: 'Agente de Contratação da Câmara Municipal de Cambé (PR)',
    },
]
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.branco};
    align-items: center;
    padding-bottom: 150px;
`

const Title = styled.h1`
    margin: 50px;
    font-size: 44px;
    color: ${COLORS.azul};
    text-align: center;
    max-width: 95vw;
`
const Card = styled.div`
    width: 400px;
    max-width: 95vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    align-items: start;
    text-align: center;
    height: 350px;
    background-color: ${COLORS.azul};
    color: ${COLORS.branco};

    @media (max-width: 360px) {
        height: 390px;
    }
`
const CardText = styled.p`
    font-size: 16px;
    font-weight: 300;
    flex: 1;
    align-self: center;
    display: flex;
    align-items: center;
`

const CardsWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (max-width: 1266px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 850px) {
        flex-direction: column;
        align-items: center;
    }
`
const TextWrapper = styled.div`
    display: flex;
    font-size: 14px;
    align-items: start;
    width: 100%;
    flex-direction: column;
    font-weight: 300;
`
const Text = styled.p`
    margin-bottom: 50px;
    width: 560px;
    text-align: center;
    max-width: 90vw;
`
export default function Depoimentos() {
    return (
        <Container id="depoimentos">
            <Title>Depoimento de Nossos Clientes</Title>
            <Text>
                O nosso produto foi testado e validado por{' '}
                <strong>
                    mais de 300 <i>beta testers</i>, além de outros clientes
                </strong>
                , que aprovaram a qualidade das respostas fornecidas pelo
                Schief.ai e certificaram a sua utilidade na realização de
                pesquisas, na elaboração de peças processuais e consultivas e na
                instrução de licitações e de contratos públicos! Confira as
                opiniões de alguns deles:
            </Text>
            <CardsWrapper>
                {depoimentos.map((card, index) => (
                    <Card key={index}>
                        <CardText>"{card.titulo}"</CardText>
                        <section>
                            <TextWrapper>
                                <span>
                                    <strong>{card.nome}</strong>
                                </span>
                                <span>{card.descricao}</span>
                            </TextWrapper>
                        </section>
                    </Card>
                ))}
            </CardsWrapper>
        </Container>
    )
}
