import { useContext } from 'react'
import { AuthContext } from '../../../../../contexts/auth'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import { HistoryContext } from '../../../../../contexts/history'

const List = styled.ul`
    list-style: none;
    border-radius: 8px;
    padding: 3px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    background-color: ${COLORS.cinzaDesbotado};
    max-width: 80%;
    width: 200px;
    z-index: 999;
`

const Item = styled.li<{ clickable: boolean; center?: boolean }>`
    border-bottom: none;
    padding: 10px;
    color: ${COLORS.cinzaEscuro};
    font-size: 13px;
    word-break: break-all;
    text-align: ${({ center }) => (center ? 'center' : '')};

    &:hover {
        background-color: ${({ clickable }) =>
            clickable ? COLORS.branco : 'transparent'};
        cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
    }

    &:last-child {
    }

    &:first-child,
    :first-child:hover {
        background-color: transparent;
        cursor: auto;

        border-bottom: 2px solid ${COLORS.cinzaClaro};
    }
`
export default function NavList() {
    const { userInfo, logout } = useContext(AuthContext)
    const { limits } = useContext(HistoryContext)
    return (
        <List>
            <Item clickable={false} center>
                {userInfo.email}
            </Item>
            <Item clickable={false}>
                Uso: {limits.current_answers}/{limits.max_answers}{' '}
            </Item>
            {/* <Item
                clickable={true}
                onClick={() => (window.location.pathname = '/configuracoes')}
            >
                Configurações
            </Item> */}
            <Item
                clickable={true}
                onClick={() => (window.location.href = '/planos')}
            >
                Assinar
            </Item>
            <Item clickable={true} onClick={logout}>
                Sair
            </Item>
        </List>
    )
}
