import { messageType } from '../../../../../types/message'
import userIcon from '../../../../../assets/user.svg'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import Tools from './tools'
import Markdown from 'react-markdown'
interface MessageProps {
    message: messageType
    arrayLength: number
    index: number
}

const MessageContainer = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin: 20px 0;
`

const Identity = styled.div`
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    & p {
        font-size: 12px;
        font-weight: 700;
        color: ${COLORS.cinzaEscuro};
    }

    & img {
        width: 20px;
    }
`

const Text = styled.p`
    font-size: 17px;
    color: ${COLORS.cinzaEscuro};
    word-wrap: wrap;
    line-height: 1.6;
    max-width: 627px;
`
export default function Message({ arrayLength, message, index }: MessageProps) {
    const isUser = !!message.author_id
    const lastAssistantMessage = arrayLength - 1 === index
    return (
        <MessageContainer>
            <Identity>
                <img src={userIcon} alt="Ícone de usuário" />
                <p>{isUser ? 'Você' : 'Schief'}</p>
            </Identity>
            <Text>
                <Markdown>{message.content}</Markdown>
                {!isUser && (
                    <Tools
                        lastAssistantMessage={lastAssistantMessage}
                        message={message}
                    />
                )}
            </Text>
        </MessageContainer>
    )
}
