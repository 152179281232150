import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

const Embed = styled.embed`
    top: 0;
    left: 0;
    width: 80vw;
    height: 80vh;
    z-index: 66;
`

const Modal = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
`

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 55;
    background-color: #00000023;
`

interface PDFModalProps {
    item: any
    setModal: Dispatch<SetStateAction<any | null>>
}

export default function PDFModal({ item, setModal }: PDFModalProps) {
    return (
        <Modal>
            <Background onClick={() => setModal(null)}></Background>
            <Embed src={item} type="application/pdf" />
        </Modal>
    )
}
