import styled from 'styled-components'
import { COLORS } from '../utils/colors'
import PDFModal from './PDFModal'
import { useState } from 'react'
import privFile from '../assets/files/[v1] Política de Privacidade - Fase de Testes.pdf'
import testFile from '../assets/files/[v1] Política de Testes - Fase de Testes.pdf'
import termFile from '../assets/files/[v1] Termos de Uso - Schief - Fase de Testes.pdf'

const FooterStyled = styled.footer`
    background-color: ${COLORS.cinzaEscuro};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    position: absolute;
    z-index: 33;
    width: 100vw;
    bottom: 0;
    gap: 20px;
`
const Link = styled.a`
    color: ${COLORS.cinzaDesbotado};
    font-size: 13px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`
export default function Footer() {
    const [pdf, setPdf] = useState(null)
    return (
        <FooterStyled>
            <Link onClick={() => setPdf(termFile)}>Termos de Uso</Link>
            <Link onClick={() => setPdf(privFile)}>
                Política de Privacidade
            </Link>
            {/* <Link onClick={() => setPdf(testFile)}>Política de Testes</Link> */}
            {!!pdf && <PDFModal item={pdf} setModal={setPdf} />}
        </FooterStyled>
    )
}
