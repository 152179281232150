import styled from 'styled-components'
import mock from '../../assets/mock_capa.svg'
import { COLORS } from '../../utils/colors'

const Container = styled.div`
    background-color: ${COLORS.azul};
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
`
const Text = styled.p`
    margin-bottom: 30px;
    color: ${COLORS.branco};
    font-weight: 300;
    width: 550px;
    text-align: center;
    font-size: 18px;
    max-width: 90vw;

    i {
        white-space: break-spaces;
    }
`
const Subtext = styled.p`
    font-style: normal;
    display: inline-block;
    margin-top: 30px;
    font-size: 24px;
    font-weight: 700;
`

const Mock = styled.img`
    width: 800px;
    max-width: 90vw;
`

export default function Capa() {
    return (
        <Container>
            <Text>
                O seu Assistente Virtual especializado em{' '}
                <strong>Licitações Públicas e Contratos Administrativos</strong>
                <Subtext>
                    Inteligência Artificial Generativa & Big Data & Curadoria
                    especializada
                </Subtext>
            </Text>
            <Mock src={mock} />
        </Container>
    )
}
