import styled from 'styled-components'
import logoTransparente from '../../assets/logo_fundo_azul.svg'
import { COLORS } from '../../utils/colors'
import Footer from '../../globalComponents/footer'
import Cards from './cards'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: ${COLORS.azul};
    gap: 30px;
    min-height: 100vh;
`
const Logo = styled.img`
    width: 180px;
    cursor: pointer;
`
const Title = styled.h1`
    font-size: 36px;
    color: ${COLORS.branco};
    font-weight: 700;
    margin-top: 10px;
`

export default function Plans() {
    return (
        <Page>
            <Logo
                onClick={() => (window.location.pathname = '/')}
                src={logoTransparente}
            />
            <Title>Assinaturas</Title>
            <Cards />
            <Footer />
        </Page>
    )
}
