import styled from 'styled-components'
import { COLORS } from '../utils/colors'
import { useState } from 'react'

const Label = styled.label`
    color: ${COLORS.vermelho};
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
`

export default function useError() {
    const [error, setError] = useState('')

    function showError(text: string, doNotExpires?: boolean) {
        setError(text)
        setTimeout(
            () => {
                setError('')
            },
            doNotExpires ? 3000000 : 2000
        )
    }

    const ErrorComponent = error ? <Label>{error}</Label> : null

    return { ErrorComponent, showError }
}
