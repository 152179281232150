import styled from 'styled-components'
import { COLORS } from '../../../utils/colors'
import FormField from '../../../globalComponents/formField'
import { useContext, useEffect, useState } from 'react'
import SubmitButton from '../../../globalComponents/SubmitButton'
import { useLoading } from '../../../globalComponents/useLoading'
import { AuthApi } from '../../../services/AuthApi.service'
import { AuthContext } from '../../../contexts/auth'
import useError from '../../../globalComponents/useError'
import ReturnIcon from '../../../assets/return.svg'

const Form = styled.form`
    width: 550px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.cinzaClaro};
    border-radius: 10px;
    padding: 20px;

    & h1 {
        font-size: 30px;
        color: ${COLORS.cinzaEscuro};
    }
`

const Body = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`

const Return = styled.img`
    cursor: pointer;
    width: 30px;
    position: absolute;
    top: -50px;
    left: 0%;
`

export default function EditForm() {
    // const { authData } = useContext(AuthContext)
    // const [name, setName] = useState<string>(authData?.userName || '')
    // const [email, setEmail] = useState<string>(authData?.email || '')
    // const { isLoading, Spinner, setIsLoading } = useLoading()
    // const [feedbackMessage, setFeedbackMessage] = useState('')

    // useEffect(() => {
    //     setEmail(authData?.email!)
    //     setName(authData?.userName!)
    // }, [authData])
    // async function handleEdit() {
    //     setIsLoading(true)
    //     try {
    //         const api = new AuthApi()
    //         const response = await api.editUser(name, email)
    //         setFeedbackMessage(response)
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 1500)
    //     } catch (error) {
    //         console.log(error)
    //     } finally {
    //         setIsLoading(false)
    //     }
    // }
    return <></>
    // (
    // <Form>
    //     <h1>Configurações</h1>
    //     <Body>
    //         <Return
    //             onClick={() => (window.location.pathname = '/')}
    //             src={ReturnIcon}
    //             alt="Botão de retornar para página anterior"
    //         />
    //         <FormField
    //             inputType="text"
    //             state={name}
    //             setState={setName}
    //             label="Editar nome"
    //         />
    //         <FormField
    //             inputType="email"
    //             state={email}
    //             setState={setEmail}
    //             label="Editar endereço de e-mail"
    //         />
    //         <SubmitButton
    //             disabled={isLoading}
    //             label="Salvar"
    //             onclick={handleEdit}
    //         />
    //         <ErrorText text={feedbackMessage} />
    //     </Body>
    //     <Spinner />
    // </Form>
    // )
}
