import styled from 'styled-components'
import logo from '../../assets/logo.png'
import { COLORS } from '../../utils/colors'

const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    padding-bottom: 10px;
    width: 100%;
    border-top: 1px solid ${COLORS.azul};

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: ${COLORS.azul};
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: ${COLORS.azul};
        font-weight: 500;
    }

    a:hover {
        color: ${COLORS.vermelho};
    }
`
const Logo = styled.img`
    width: 230px;
`
const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    flex: 1;
    p {
        font-size: 13px;
        white-space: pre-line;
    }
`
const FinalText = styled.p`
    margin-top: 20px;
    font-size: 13px;
`

const MiddleText = styled.p`
    text-align: center;
    white-space: pre-line;
    font-size: 18px !important;
`
const Row = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    @media (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: start;
        gap: 30px;
    }
`
const Columns = styled.div`
    display: flex;
    flex-direction: column;
`
export default function Footer() {
    return (
        <Container>
            <Row>
                <Block>
                    <Logo src={logo} />
                    <p>{`CNPJ: 55.276.631/0001-20
                Rua Esteves Junior, nº 50 - Centro, Florianópolis - SC - 
                CEP: 88015-130`}</p>
                </Block>
                <Block>
                    <MiddleText></MiddleText>
                </Block>
                <Row>
                    <Columns>
                        <h2>Mapa</h2>
                        <a href="#sobre">Quem Somos?</a>
                        <a href="#produto">Nosso Produto</a>
                        <a href="#assinatura">Planos de Assinaturas</a>
                        <a href="#depoimentos">Depoimentos</a>
                        <a href="#faq">Dúvidas</a>
                    </Columns>
                    <Columns>
                        <h2>Contato</h2>
                        <a href="mailto:atendimento@schief.ai" target="_blank">
                            atendimento@schief.ai
                        </a>
                        <a
                            href="https://www.instagram.com/schief.ai"
                            target="_blank"
                        >
                            Instagram
                        </a>
                    </Columns>
                </Row>
            </Row>
            <FinalText>
                © 2024 - Schief.ai - Todos os direitos reservados
            </FinalText>
        </Container>
    )
}
