import styled from 'styled-components'
import { threadType } from '../../../../../types/thread'
import { useContext } from 'react'
import { HistoryContext } from '../../../../../contexts/history'
import { COLORS } from '../../../../../utils/colors'
import archiveIcon from '../../../../../assets/archive.svg'
import deleteIcon from '../../../../../assets/delete.svg'

interface ListItemProps {
    item: threadType
}

const ListItemStyled = styled.li<{ isSelected: boolean }>`
    font-weight: 300;
    background-color: ${({ isSelected }) =>
        isSelected ? COLORS.cinzaDesbotado : 'transparent'};
    cursor: pointer;
    width: 250px;
    align-self: center;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
    position: relative;
    display: flex;
    & span {
        text-align: start;
        padding: 10px 15px;
        display: inline-block;
        height: 100%;
        flex: 1;
    }
`

const Icon = styled.img<{ position: 'left' | 'right' }>`
    position: absolute;
    width: 20px;
    top: 10px;
    right: ${({ position }) => (position === 'left' ? '40px' : `15px`)};
    opacity: 0.2;
    transition: all 0.3s;

    &:hover {
        opacity: 1;
    }
`
export default function ListItem({ item }: ListItemProps) {
    const { selected, setSelected, archiveThread, deleteThread } =
        useContext(HistoryContext)
    const isSelected = selected?.guid === item.guid

    function toggleItem() {
        if (isSelected) {
            return
        }
        setSelected(item)
    }

    return (
        <ListItemStyled isSelected={isSelected}>
            <span onClick={toggleItem}>
                {item.name === 'Undefined'
                    ? 'Novo Chat'
                    : item.name.slice(0, 23)}
                {item.name?.length < 23 ? '' : '...'}
            </span>
            <Icon
                onClick={() => archiveThread(item.guid)}
                position="left"
                src={archiveIcon}
                alt="Arquivar Thread"
            />
            <Icon
                onClick={() => deleteThread(item.guid)}
                position="right"
                src={deleteIcon}
                alt="Arquivar Thread"
            />
        </ListItemStyled>
    )
}
