import styled from 'styled-components'
import LoginForm from './components/Form/LoginForm'
import logoTransparente from '../../assets/logoTransparente.png'
import { COLORS } from '../../utils/colors'
import Footer from '../../globalComponents/footer'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: ${COLORS.cinzaClaro};
    min-height: 100vh;
    gap: 30px;
    position: relative;
`
const Logo = styled.img`
    width: 180px;
`
export default function Login() {
    const flag = window.location.search.replace('?', '')
    return (
        <Page>
            <Logo src={logoTransparente} />
            <LoginForm flag={flag} />
            <Footer />
        </Page>
    )
}
