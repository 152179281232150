import styled from 'styled-components'
import Cards, { Card, Middle, Text, ValueWrapper, Values } from '../plans/cards'
import { COLORS } from '../../utils/colors'
import { useState } from 'react'

const Container = styled.div`
    background-color: ${COLORS.azul};
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 50px;
    align-items: center;
`
const Title = styled.h1`
    font-size: 44px;
    color: ${COLORS.branco};
    margin: 40px 0;
    text-align: center;
`

export default function Plans() {
    return (
        <Container id="assinatura">
            <Title>Assinaturas</Title>
            <Cards incluirGratuito />
        </Container>
    )
}
