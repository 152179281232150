import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import AppRoutes from './Routes'
import ReactGA from 'react-ga4'

const ga4_id = process.env.REACT_APP_GA4_ID ? process.env.REACT_APP_GA4_ID : ''
if (ga4_id) {
    ReactGA.initialize(ga4_id)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <AppRoutes />
    // </React.StrictMode>
)
