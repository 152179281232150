import styled from 'styled-components'
import arrowIcon from '../assets/arrow_gray.svg'
import { Dispatch, SetStateAction } from 'react'

const StyledHideShowBarButton = styled.button<{
    show: boolean
    position: string
    offset: string
    hideValue: string
    showValue: string
}>`
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ position, offset }) => `${position}: ${offset}`};
    z-index: 5555;

    & img {
        transform: rotateZ(
            ${({ show, showValue, hideValue }) =>
                show ? showValue : hideValue}
        );
        width: 20px;
    }
`
interface HideShowBarButtonProps {
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
    offset: string
    position: 'right' | 'left'
}
export default function HideShowBarButton({
    show,
    setShow,
    position,
    offset,
}: HideShowBarButtonProps) {
    const showValue = position === 'left' ? '-90deg' : '90deg'
    const hideValue = position === 'left' ? '90deg' : '-90deg'

    return (
        <StyledHideShowBarButton
            position={position}
            showValue={showValue}
            hideValue={hideValue}
            offset={offset}
            onClick={() => setShow((prev) => !prev)}
            show={show}
        >
            <img src={arrowIcon} alt="Seta" />
        </StyledHideShowBarButton>
    )
}
