import styled from 'styled-components'
import logo from '../../assets/logoTransparente.png'
import menu from '../../assets/menu.svg'
import { COLORS } from '../../utils/colors'
import { useState } from 'react'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 0px 30px;
`
const Logo = styled.img`
    display: block;
    width: 200px;
`
const Icon = styled.img`
    display: none;

    @media (max-width: 839px) {
        width: 50px;
        display: block;
    }
`
const Menu = styled.img`
    display: none;

    @media (max-width: 839px) {
        order: 1;
        width: 50px;
        display: block;
        cursor: pointer;
    }
`
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Link = styled.a`
    font-weight: 700;
    font-size: 18px;
    color: ${COLORS.azul};
    text-decoration: none;

    &:hover {
        color: ${COLORS.vermelho};
    }

    @media (max-width: 839px) {
        display: none;
    }
`

const Buttons = styled.div`
    @media (max-width: 839px) {
        display: none;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    & button {
        background-color: ${COLORS.azul};
        color: ${COLORS.branco};
        padding: 3px 5px;
        border-radius: 8px;
        min-width: 100px;
        font-weight: 700;
        font-size: 17px;
        cursor: pointer;
        transition: all 0.3s;
        border: 2px solid transparent;
    }
    & button:hover {
        background-color: green;
    }
`
const MenuItemWrapper = styled.div`
    background-color: ${COLORS.branco};
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 80px;
    border-radius: 0 0 0 10px;
    cursor: pointer;
`
const MenuItem = styled.a`
    font-weight: 700;
    padding: 5px 20px;
    font-size: 18px;
    color: ${COLORS.azul};
    text-decoration: none;

    &:hover {
        color: ${COLORS.vermelho};
    }
`

export default function Header() {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <Container>
            <LogoContainer>
                <Logo src={logo} alt="logo schief.ai" />
                {/* <Icon src={icon} alt="ícone schief.ai" /> */}
            </LogoContainer>
            <Link href="#sobre">Quem Somos?</Link>
            <Link href="#produto">Nosso Produto</Link>
            <Link href="#assinatura">Planos de Assinaturas</Link>
            <Link href="#depoimentos">Depoimentos</Link>
            <Link href="#faq">Dúvidas</Link>
            <Menu
                onClick={() => setShowMenu((prev) => !prev)}
                src={menu}
                alt="Menu"
            />
            {showMenu && (
                <MenuItemWrapper>
                    <MenuItem href="#sobre">Quem Somos?</MenuItem>
                    <MenuItem href="#produto">Nosso Produto</MenuItem>
                    <MenuItem href="#assinatura">
                        Planos de Assinaturas
                    </MenuItem>
                    <MenuItem href="#faq">Dúvidas</MenuItem>
                    <MenuItem onClick={() => (window.location.href = '/login')}>
                        Acesso
                    </MenuItem>
                </MenuItemWrapper>
            )}
            <Buttons>
                <button
                    type="button"
                    onClick={() => (window.location.href = '/login')}
                >
                    Entrar
                </button>
            </Buttons>
        </Container>
    )
}
