import styled from 'styled-components'
import EditForm from './components/form'
import logoTransparente from '../../assets/logoTransparente.png'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
`

const Logo = styled.img`
    width: 180px;
    margin-bottom: 30px;
    cursor: pointer;
`
export default function UserConfigs() {
    return (
        <Page>
            <Logo
                src={logoTransparente}
                onClick={() => (window.location.pathname = '/')}
            />
            <EditForm />
        </Page>
    )
}
