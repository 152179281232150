import Button from '../../../../globalComponents/ButtonPattern'
import Icon from '../../../../assets/email.svg'
import Check from '../../../../assets/check.svg'
import Warning from '../../../../assets/warning.svg'
import styled from 'styled-components'
import { AuthApi } from '../../../../services/AuthApi.service'
import useError from '../../../../globalComponents/useError'
import { useState } from 'react'
import { useLoading } from '../../../../globalComponents/useLoading'

interface VerifyEmailProps {
    email: string
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    h1 {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        white-space: pre-line;
        text-align: center;
    }
`
export default function VerifyEmail({ email }: VerifyEmailProps) {
    const { ErrorComponent, showError } = useError()
    const { Spinner, isLoading, setIsLoading } = useLoading()
    const [buttonProps, setButtonProps] = useState({
        text: 'Reenviar e-mail de verificação',
        icon: Icon,
        onclick: handleVerifyEmail,
    })
    const mask =
        email.slice(0, 3) + '**********' + email.slice(13, email.length)

    async function handleVerifyEmail() {
        setIsLoading(true)
        try {
            await new AuthApi().sendEmailVerification(email)
            setButtonProps({
                text: `E-mail enviado`,
                icon: Check,
                onclick: async () => {},
            })
        } catch (error: any) {
            showError(error)
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <Container>
            <h1>
                <img src={Warning} />
                Verifique seu Email
            </h1>

            <p>
                {`Seu e-mail ${mask} ainda não foi verificado.
                 Por favor, verifique sua caixa de entrada para ativar sua conta.`}
            </p>

            <Button
                disabled={isLoading}
                onclick={buttonProps.onclick}
                text={buttonProps.text}
                IconAltText="Enviar email"
                SVGIcon={buttonProps.icon}
            />
            {isLoading && <Spinner />}
            {ErrorComponent}
        </Container>
    )
}
