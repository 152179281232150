import { Dispatch, KeyboardEvent, SetStateAction, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/colors'

interface FormFieldProps {
    label: string
    inputType: string
    state: string
    setState: Dispatch<SetStateAction<string>>
    submit: () => void
    autofocus?: boolean
}

const Campo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;

    & label {
        font-size: 12px;
        color: ${COLORS.cinzaEscuro};
    }

    & input {
        padding: 10px;
        width: 100%;
        color: ${COLORS.cinzaEscuro};
        border-radius: 5px;
        border: 1px solid ${COLORS.cinzaEscuro};
    }
`
export default function FormField({
    submit,
    label,
    inputType,
    state,
    setState,
    autofocus = false,
}: FormFieldProps) {
    async function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            submit()
        }
    }

    useEffect(() => {
        console.log(state)
    }, [state])
    return (
        <Campo>
            <label>{label}</label>
            <input
                autoFocus={autofocus}
                onKeyUp={(event) => handleKeyUp(event)}
                value={state}
                onChange={(e) => setState(e.target.value)}
                type={inputType}
            />
        </Campo>
    )
}
