import styled from 'styled-components'
import { COLORS } from '../../../../utils/colors'
import { useContext } from 'react'
import { HistoryContext } from '../../../../contexts/history'
import Message from './message/message'
import Input from './input/input'
import { breakpoints } from '../../../../utils/configs'

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;

    @media screen and (${breakpoints.sm}) {
        width: 100vw;
    }
`
const Body = styled.div<{ noMessage?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${({ noMessage }) => (noMessage ? 'justify-content: center;' : '')}
    width: 795px;
    max-width: 100%;
    padding: 10px 50px;
    overflow-y: auto;
    margin-top: 50px;
`

const SelectedSchiefMessage = styled.span`
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    text-align: center;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
`
const NoMessageText = styled.span`
    align-self: center;
    font-weight: 400;
`
export default function Chat() {
    const { messagesArray, selected, assistant } = useContext(HistoryContext)
    return (
        <Section>
            <SelectedSchiefMessage>
                {assistant?.guid
                    ? `Você selecionou o Schief ${assistant.name}.`
                    : 'Selecione um assistente no menu abaixo.'}
            </SelectedSchiefMessage>
            {!!selected?.name ? (
                <Body noMessage={false}>
                    {messagesArray?.map((item, index) => (
                        <Message
                            key={item.guid}
                            message={item}
                            arrayLength={messagesArray.length}
                            index={index}
                        />
                    ))}
                    <div id="anchor"></div>
                </Body>
            ) : (
                <Body noMessage>
                    <NoMessageText>Selecione uma conversa.</NoMessageText>
                </Body>
            )}
            <Input />
        </Section>
    )
}
