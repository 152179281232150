import styled from 'styled-components'
import logoTransparente from '../../assets/logoTransparente.png'
import { COLORS } from '../../utils/colors'
import Footer from '../../globalComponents/footer'
import { useEffect, useMemo, useState } from 'react'
import { useLoading } from '../../globalComponents/useLoading'
import { AssistantApi } from '../../services/AssistantApi.service'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: ${COLORS.cinzaClaro};
    min-height: 100vh;
    gap: 30px;
    position: relative;

    h2,
    h3 {
        text-align: center;
    }
`
const Logo = styled.img`
    width: 180px;
`
const Erro = styled.p`
    font-size: 14px;
    color: ${COLORS.vermelho};
    text-align: center;
`
export default function ValidateSubscriptionPage() {
    const { Spinner, isLoading, setIsLoading } = useLoading()
    const [showError, setShowError] = useState(false)

    const search = useMemo(() => {
        return window.location.search
    }, [window.location.search])
    const token = useMemo(() => {
        return search.slice(1, search.length)
    }, [search])

    async function confirmPurchase() {
        if (!token) return
        try {
            setIsLoading(true)
            const api = new AssistantApi()
            await api.confirmPurchase(token)
            setTimeout(() => {
                window.location.href = '/'
            }, 2000)
        } catch (error: any) {
            setShowError(true)
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!token) return
        confirmPurchase()
    }, [token])

    return (
        <Page>
            <Logo src={logoTransparente} />
            <div>
                <h2>Validando inscrição...</h2>
                <br />
                <h4>Sua assinatura deve estar liberada em instantes!</h4>
            </div>
            {isLoading && <Spinner />}
            {showError && (
                <Erro>
                    Algo deu errado. Por favor entre em contato com nosso time
                    de suporte através do email{' '}
                    <a href="mailto:atendimento@schief.ai">
                        atentimento@schief.ai
                    </a>
                </Erro>
            )}
            <Footer />
        </Page>
    )
}
