import { Dispatch, SetStateAction, useState } from 'react'
import returnIcon from '../../../../assets/return.svg'
import styled from 'styled-components'
import { pageType } from '../../../../types/loginPages'
import FormField from '../../../../globalComponents/formField'
import SubmitButton from '../../../../globalComponents/SubmitButton'
import { COLORS } from '../../../../utils/colors'
import privFile from '../../../../assets/files/[v1] Política de Privacidade - Fase de Testes.pdf'
import testFile from '../../../../assets/files/[v1] Política de Testes - Fase de Testes.pdf'
import termFile from '../../../../assets/files/[v1] Termos de Uso - Schief - Fase de Testes.pdf'
import PDFModal from '../../../../globalComponents/PDFModal'

const SubTitle = styled.h2`
    font-size: 18px;
    margin: 10px 0;
    color: ${COLORS.cinzaEscuro};
`

const CheckboxField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 10px;
    margin: 10px 0;
    font-size: 15px;

    & input {
        font-size: 30px;
        width: 30px;
    }

    & strong {
        text-decoration: underline;
        cursor: pointer;
    }
`

const ReturnDiv = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    & span {
        font-size: 13px;
        color: ${COLORS.cinzaEscuro};
    }
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    & h3 {
        font-size: 15px;
        color: ${COLORS.cinzaEscuro};
    }
`

interface CreatePasswordProps {
    handleSignUp: () => void
    password: string
    setPassword: Dispatch<SetStateAction<string>>
    setPage: Dispatch<SetStateAction<pageType>>
}
export default function CreatePassword({
    handleSignUp,
    password,
    setPassword,
    setPage,
}: CreatePasswordProps) {
    const [confirmPassword, setConfirmPassword] = useState('')
    const [checked, setChecked] = useState(false)
    const [modal, setModal] = useState(null)

    return (
        <Body>
            <ReturnDiv onClick={() => setPage('Cadastre-se')}>
                <img src={returnIcon} alt="Retornar para Inscreva-se" />
                <span>Voltar</span>
            </ReturnDiv>
            <SubTitle>Ótimo! Só mais um passo. </SubTitle>

            <h3>Criar senha:</h3>
            <FormField
                autofocus
                submit={handleSignUp}
                inputType="password"
                state={password}
                setState={setPassword}
                label="Senha"
            />
            <FormField
                submit={handleSignUp}
                inputType="password"
                state={confirmPassword}
                setState={setConfirmPassword}
                label="Confirmar senha"
            />
            <CheckboxField>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked((prev) => !prev)}
                />
                <p>
                    Ao me cadastrar, declaro que li e concordo com os
                    <strong onClick={() => setModal(termFile)}>
                        Termos de Uso
                    </strong>
                    ,
                    <strong onClick={() => setModal(privFile)}>
                        Política de Privacidade
                    </strong>
                    e
                    <strong onClick={() => setModal(testFile)}>
                        Política de Testes
                    </strong>
                    .
                </p>
            </CheckboxField>
            <SubmitButton
                disabled={
                    password !== confirmPassword ||
                    !password ||
                    !confirmPassword ||
                    !checked
                }
                label="Confirmar"
                onclick={handleSignUp}
            />
            {!!modal && <PDFModal item={modal} setModal={setModal} />}
        </Body>
    )
}
