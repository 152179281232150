import styled from 'styled-components'
import { COLORS } from '../../../utils/colors'
import { useState } from 'react'
import { CookiesService } from '../../../services/Cookie.service'

const texts = {
    plano1: {
        mensal: {
            quantidade: '50 Consultas/mês',
            valor: 'R$ 295,00/mês',
        },
        anual: {
            quantidade: '50 Consultas/mês',
            valor: `R$ 3.300,00/ano
            (equivalente a R$275/mês)`,
        },
    },
    plano2: {
        mensal: {
            quantidade: '150 Consultas/mês',
            valor: 'R$ 875,00/mês',
        },
        anual: {
            quantidade: '150 Consultas/mês',
            valor: `R$ 9.810,00/ano
            (equivalente a R$817,50/mês)`,
        },
    },
}
const CardsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
    @media (max-width: 970px) {
        flex-direction: column;
    }
`

export const Card = styled.div<{ last?: boolean; height?: string }>`
    border-radius: 10px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    width: 250px;
    max-width: 95vw;
    height: ${({ height }) => (height ? height : '400px')};
    background-color: ${COLORS.branco};
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 1px 1px white;
    & h2 {
        font-size: 25px;
        font-weight: 700;
        white-space: pre-line;
        text-align: center;
    }

    & h3 {
        font-weight: 700;
    }

    & button {
        background-color: ${COLORS.vermelho};
        color: ${COLORS.branco};
        font-size: 15px;
        font-weight: 700;
        width: 120px;
        padding: 8px 0;
        border-radius: 50px;
        border: none;
        box-shadow: 1px 1px 3px 1px #d4d3d3;
        cursor: pointer;
        transition: all 0.5s;
    }

    & button:hover {
        background-color: ${COLORS.azul};
    }
`
const Slider = styled.div`
    background-color: ${COLORS.branco};
    display: flex;
    justify-content: space-evenly;
    border-radius: 50px;
    padding: 3px;
    cursor: pointer;
`
export const Item = styled.div<{ selected: boolean }>`
    text-align: center;
    padding: 3px 8px;
    font-weight: 700;
    border-radius: 50px;
    background-color: ${({ selected }) =>
        selected ? COLORS.vermelho : COLORS.branco};
    color: ${({ selected }) => (selected ? COLORS.branco : COLORS.azul)};
    width: 100%;
`
export const Middle = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
`
export const Text = styled.p`
    display: block;
    font-size: 15px;
    padding: 0 30px;
`

export const ValueWrapper = styled.p`
    display: flex;
    flex-direction: column;
`
export const Values = styled.p`
    font-size: 15px;
    white-space: pre-line;
`

type slidersType = 'mensal' | 'anual'

interface CardsProps {
    incluirGratuito?: boolean
}

export default function Cards({ incluirGratuito = false }: CardsProps) {
    const [sliderMode, setSliderMode] = useState<slidersType>('mensal')

    function handleClick(route: string, flag: string | null) {
        if (flag) {
            new CookiesService().saveFlag(flag)
        }
        window.location.href = route
    }

    return (
        <>
            <Slider>
                <Item
                    onClick={() => setSliderMode('mensal')}
                    selected={sliderMode === 'mensal'}
                >
                    Mensal
                </Item>
                <Item
                    onClick={() => setSliderMode('anual')}
                    selected={sliderMode === 'anual'}
                >
                    Anual
                </Item>
            </Slider>
            <CardsWrapper>
                {incluirGratuito && (
                    <Card>
                        <h2>{`Plano
Gratuito`}</h2>
                        <Middle>
                            <Text>
                                Para iniciantes no universo das Licitações e
                                Contratos Administrativos.
                            </Text>
                            <ValueWrapper>
                                <Values>3 Consultas/mês</Values>
                            </ValueWrapper>
                        </Middle>
                        <button onClick={() => handleClick('/login', null)}>
                            Testar
                        </button>
                    </Card>
                )}
                <Card>
                    <h2>{`Plano 
            Profissional I`}</h2>
                    <Middle>
                        <Text>
                            Para quem trabalha <strong>cotidianamente</strong>{' '}
                            com Licitações e Contratos Administrativos.
                        </Text>
                        <ValueWrapper>
                            <Values>
                                {texts.plano1[sliderMode].quantidade}
                            </Values>
                            <Values>{texts.plano1[sliderMode].valor}</Values>
                        </ValueWrapper>
                    </Middle>
                    <button
                        onClick={() =>
                            handleClick(
                                `/login?plano1/${sliderMode}`,
                                `plano1/${sliderMode}`
                            )
                        }
                    >
                        Assinar
                    </button>
                </Card>
                <Card>
                    <h2>{`Plano 
            Profissional II`}</h2>
                    <Middle>
                        <Text>
                            Para quem trabalha intensamente e com alto volume de
                            Licitações e Contratos Administrativos
                        </Text>
                        <ValueWrapper>
                            <Values>
                                {texts.plano2[sliderMode].quantidade}
                            </Values>
                            <Values>{texts.plano2[sliderMode].valor}</Values>
                        </ValueWrapper>
                    </Middle>
                    <button
                        onClick={() =>
                            handleClick(
                                `/login?plano2${sliderMode}`,
                                `plano2/${sliderMode}`
                            )
                        }
                    >
                        Assinar
                    </button>
                </Card>
                <Card>
                    <h2>{`Plano 
                    Institucional`}</h2>
                    <Middle>
                        <Text>
                            Para equipes de{' '}
                            <strong>órgãos públicos ou empresas</strong>{' '}
                            trabalham com licitações públicas e contratos
                            administrativos.
                        </Text>
                    </Middle>
                    <button
                        onClick={() =>
                            (window.location.href = 'http://eepurl.com/iSI0PU')
                        }
                    >
                        Contato
                    </button>
                </Card>
            </CardsWrapper>
        </>
    )
}
