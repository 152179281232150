import styled from 'styled-components'
import { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../contexts/auth'
import SubmitButton from '../../../../globalComponents/SubmitButton'
import { COLORS } from '../../../../utils/colors'
import useError from '../../../../globalComponents/useError'
import { useLoading } from '../../../../globalComponents/useLoading'
import FormField from '../../../../globalComponents/formField'
import Divisor from './divisor'
import CreatePassword from './createPassword'
import { pageType } from '../../../../types/loginPages'
import PDFModal from '../../../../globalComponents/PDFModal'
import { CookiesService } from '../../../../services/Cookie.service'
import testFile from '../../../../assets/files/[v1] Política de Testes - Fase de Testes.pdf'
import VerifyEmail from './verifyEmail'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    gap: 20px;
    padding: 30px 0;
    width: 560px;
    background-color: ${COLORS.branco};

    & h1 {
        font-size: 15px;
        color: ${COLORS.cinzaEscuro};
    }
`

const GoogleButton = styled.button`
    font-size: 13px;
    color: ${COLORS.cinzaEscuro};
    cursor: pointer;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    border: 1px solid black;
    background-color: ${COLORS.branco};
`
const SelectionButton = styled.button<{ selected: boolean; primeiro: boolean }>`
    padding: 10px 0;
    width: 250px;
    background-color: ${COLORS.azul};
    color: ${COLORS.branco};
    opacity: ${({ selected }) => (selected ? '1' : '0.25')};
    font-weight: 700;
    font-size: 13px;
    border-radius: ${({ primeiro }) =>
        primeiro ? '10px 0 0 10px' : '0 10px 10px 0'};
    cursor: pointer;
`

const NameContainer = styled.div`
    display: flex;
    gap: 10px;
`

const Body = styled.div`
    width: 50%;
`
const GoToResetPassword = styled.a`
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-size: 13px;
`

const CheckboxField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin: 10px 0;
    font-size: 15px;
    color: ${COLORS.cinzaEscuro};
    border-radius: 8px 8px 0 0;
    width: 80%;
    padding: 10px;

    position: fixed;
    top: 0;
    background-color: ${COLORS.cinzaClaro};
    z-index: 99999;

    & p {
        font-size: 15px;
    }
    & input {
        font-size: 30px;
        width: 30px;
    }

    & strong {
        text-decoration: underline;
        cursor: pointer;
    }

    & div {
        width: 300px;
    }
`

interface LoginFormProps {
    flag?: 'gratuito' | 'plano1' | 'plano2' | any
}

export default function LoginForm({ flag }: LoginFormProps) {
    const { login, signUp } = useContext(AuthContext)
    const { Spinner, setIsLoading } = useLoading()
    const [page, setPage] = useState<pageType>(flag ? 'Cadastre-se' : 'Entrar')
    const { ErrorComponent, showError } = useError()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [pdf, setPdf] = useState(null)
    const [checked, setChecked] = useState(false)

    const cookieService = useMemo(() => new CookiesService(), [])

    // function doAgreement() {
    //     if (!cookieService.getCheckboxStatus()) {
    //         setPdf(testFile)
    //         return
    //     }
    //     handleLogin()
    // }

    async function handleLogin() {
        if (!email || !password) {
            return
        }
        setIsLoading(true)
        try {
            await login(email, password)
            cookieService.saveFlag(flag)
            // cookieService.setCheckboxStatus()
        } catch (error: any) {
            if (error === 403) {
                setPage('Verificar Email')
            }
            setPdf(null)
            setChecked(false)
            showError(error)
        } finally {
            setIsLoading(false)
        }
    }
    async function handleSignUp() {
        if (!firstName || !lastName || !email || !password) {
            return
        }
        setIsLoading(true)
        try {
            await signUp(firstName, lastName, email, password)
            cookieService.saveFlag(flag)
        } catch (error: any) {
            if (error === 403) {
                setPage('Verificar Email')
            }
            showError(error)
        } finally {
            setIsLoading(false)
        }
    }

    function handleGoToCreatePassword() {
        if (!firstName || !lastName || !email) {
            return
        }
        setPage('Criar Senha')
    }

    return (
        <>
            {!!pdf && (
                <>
                    <CheckboxField>
                        <p>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => setChecked((prev) => !prev)}
                            />
                            Declaro que li e concordo com a{' '}
                            <strong onClick={() => setPdf(testFile)}>
                                Política de Testes
                            </strong>
                            .
                        </p>
                        <div>
                            <SubmitButton
                                disabled={!checked}
                                onclick={handleLogin}
                                label="Confirmar e Fazer Login"
                            />
                        </div>
                    </CheckboxField>
                    <PDFModal item={pdf} setModal={setPdf} />
                </>
            )}
            <Form>
                {(page === 'Entrar' || page === 'Cadastre-se') && (
                    <>
                        <div>
                            <SelectionButton
                                primeiro={true}
                                selected={page === 'Entrar'}
                                type="button"
                                onClick={() => setPage('Entrar')}
                            >
                                Entrar
                            </SelectionButton>
                            <SelectionButton
                                primeiro={false}
                                selected={page === 'Cadastre-se'}
                                type="button"
                                onClick={() => setPage('Cadastre-se')}
                            >
                                Cadastre-se
                            </SelectionButton>
                        </div>
                        <h1>{page}</h1>
                        <Body>
                            {/* <GoogleButton type="button">
                                {page} com o Google
                            </GoogleButton> */}
                            <Divisor />

                            {page === 'Cadastre-se' && (
                                <>
                                    <NameContainer>
                                        <FormField
                                            autofocus
                                            submit={handleGoToCreatePassword}
                                            inputType="text"
                                            state={firstName}
                                            setState={setFirstName}
                                            label="Nome"
                                        />
                                        <FormField
                                            submit={handleGoToCreatePassword}
                                            inputType="text"
                                            state={lastName}
                                            setState={setLastName}
                                            label="Sobrenome"
                                        />
                                    </NameContainer>
                                    <FormField
                                        submit={handleGoToCreatePassword}
                                        inputType="email"
                                        state={email}
                                        setState={setEmail}
                                        label="Endereço de e-mail"
                                    />
                                    <SubmitButton
                                        disabled={
                                            !firstName || !lastName || !email
                                        }
                                        label={page}
                                        onclick={handleGoToCreatePassword}
                                    />
                                    {ErrorComponent}
                                </>
                            )}

                            {page === 'Entrar' && (
                                <>
                                    <FormField
                                        autofocus
                                        submit={handleLogin}
                                        inputType="email"
                                        state={email}
                                        setState={setEmail}
                                        label="Endereço de e-mail"
                                    />
                                    <FormField
                                        submit={handleLogin}
                                        inputType="password"
                                        state={password}
                                        setState={setPassword}
                                        label="Senha"
                                    />
                                    <SubmitButton
                                        disabled={!email || !password}
                                        label={page}
                                        onclick={handleLogin}
                                    />
                                    <GoToResetPassword href="/login/reset_password">
                                        Esqueci minha senha
                                    </GoToResetPassword>
                                    {ErrorComponent}
                                </>
                            )}
                        </Body>
                    </>
                )}

                {page === 'Criar Senha' && (
                    <>
                        <CreatePassword
                            password={password}
                            setPassword={setPassword}
                            handleSignUp={handleSignUp}
                            setPage={setPage}
                        />
                        {ErrorComponent}
                    </>
                )}

                {page === 'Verificar Email' && <VerifyEmail email={email} />}
                <Spinner></Spinner>
            </Form>
        </>
    )
}
