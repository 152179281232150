// brand colors

export const COLORS = {
    azul: '#050B29',
    vermelho: '#B31417',
    branco: '#FFFFFF',
    cinzaClaro: '#F6F6F6',
    cinzaEscuro: '#3A3A3A',
    cinzaDesbotado: '#e6e6e6',
}
