import { useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../utils/colors'

const duvidas = [
    {
        pergunta: 'Como o Schief.ai fornece respostas tão acuradas?',
        resposta:
            'Nós utilizamos uma tecnologia de inteligência artificial generativa baseada em large language models aplicada a big data jurídico. Assim, em cada uma das respostas, o Schief.ai leva em consideração, além das leis, a jurisprudência dos tribunais, sobretudo dos tribunais superiores, como o Supremo Tribunal Federal, o Superior Tribunal de Justiça e o Tribunal de Contas da União. Isso garante que todas as perguntas sejam corretamente compreendidas e que as respostas sejam acuradas.',
    },
    {
        pergunta: 'As assinaturas dão direito a atualizações gratuitas?',
        resposta:
            'Sim. A Schief.ai está trabalhando para desenvolver novas funcionalidades para o assistente jurídico virtual e  aprimorar a big data utilizada. Cada uma das assinaturas garante aos usuários as atualizações-padrão da tecnologia.',
    },
    {
        pergunta: 'Como funciona o pagamento? Posso parcelar?',
        resposta:
            'Nos planos mensais, o pagamento será realizado com recorrência mensal contada a partir da data de assinatura do plano. Nos planos anuais, o pagamento será realizado à vista, com desconto correspondente em relação aos pagamentos mensais, e também será recorrente a partir da data de assinatura do plano.',
    },
    {
        pergunta: 'Existe suporte em caso de dúvidas?',
        resposta:
            'Sim. A nossa equipe está disponível para realizar atendimentos por meio do endereço de e-mail atendimento@schief.ai.',
    },
    {
        pergunta: 'Como funciona o Schief?',
        resposta:
            'O Schief é um assistente jurídico virtual que combina inteligência artificial generativa de textos a partir de large language models e big data jurídico. Desse modo, ele é capaz de produzir textos jurídicos para auxiliar na redação de peças processuais e consultivas e na solução de dúvidas e de questões acerca de licitações e contratos públicos.',
    },
    {
        pergunta: 'Posso alterar o meu plano de assinatura?',
        resposta:
            'Sim. Após registrar-se como usuário do Plano Gratuito ou assinar um dos Planos Profissionais (I e II), você poderá alterar a sua assinatura, migrando para planos mais avançados ou, ainda, cancelando a sua assinatura no prazo de vencimento.',
    },
]

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
`
const Title = styled.h1`
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
`

const FaqWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1000px;
    max-width: 95vw;
`

const Item = styled.div<{ selected?: boolean }>`
    margin: 20px;
    background-color: ${COLORS.cinzaDesbotado};
    text-align: start;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 1px ${COLORS.cinzaDesbotado};
    overflow: hidden;
    height: auto;
    max-height: ${({ selected }) => (selected ? '1000px' : '40px')};
    transition: max-height 0.3s ease-in-out;
    cursor: pointer;
    width: 400px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media (max-width: 375px) {
        max-height: ${({ selected }) => (selected ? '1000px' : '50px')};
        text-align: center;
    }
`
const Pergunta = styled.p`
    font-weight: 700;
    margin: 10px 0 15px;
`
const Resposta = styled.p`
    font-weight: 300;
    padding: 20px;
`
export default function Faq() {
    const [selected, setSelected] = useState<number | null>(null)

    function toggle(index: number | null) {
        setSelected(index === selected ? null : index)
    }
    return (
        <Container id="faq">
            <Title>Dúvidas Frequentes</Title>
            <FaqWrapper>
                {duvidas.map((duvida, index) => (
                    <Item
                        onClick={() => toggle(index)}
                        selected={selected === index}
                        key={index}
                    >
                        <Pergunta>{duvida.pergunta}</Pergunta>
                        <Resposta>{duvida.resposta}</Resposta>
                    </Item>
                ))}
            </FaqWrapper>
        </Container>
    )
}
