import { useState } from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
      0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
    position: fixed;
    top: 40%;
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px;

    &::after {
        content: ' ';
        display: block;
        width: 34px;
        height: 34px;
        margin: 8px;
        border-radius: 50%;
        border: 6.4px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: ${spin} 1.2s linear infinite;
    }
`

export const useLoading = () => {
    const [isLoading, setIsLoading] = useState(false)
    return {
        Spinner: isLoading ? LoadingSpinner : Empty,
        isLoading,
        setIsLoading,
    }
}

const LoadingSpinner = () => {
    return <Spinner></Spinner>
}

const Empty = () => {
    return <></>
}
