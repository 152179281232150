import axios from 'axios'
import { CookiesService } from './Cookie.service'

const MyAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

MyAxiosInstance.interceptors.request.use(
    (config) => {
        const cookies = new CookiesService()
        const { access_token } = cookies.getAuthData()
        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

function logout() {
    const cookies = new CookiesService()
    cookies.removeAuthData()
    cookies.removeAssistant()

    window.location.pathname = '/login'
}

MyAxiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response?.status === 401) {
            console.log('Erro 401: Não autorizado')
            logout()
        }
        return Promise.reject(error)
    }
)

export default MyAxiosInstance
