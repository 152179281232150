import styled from 'styled-components'
import { COLORS } from '../../../../utils/colors'

const StyledDivisor = styled.div`
    display: flex;
    /* margin-top: 20px; */
    margin: 15px 0 20px;
    gap: 20px;
    & div {
        width: 100%;
        border-bottom: 1px solid black;
        transform: translateY(-8px);
    }

    & span {
        font-size: 12px;
        font-weight: 700;
        color: ${COLORS.cinzaEscuro};
    }
`
export default function Divisor() {
    return (
        <StyledDivisor>
            <div></div>
            {/* <span>OU</span> */}
            {/* <div></div> */}
        </StyledDivisor>
    )
}
