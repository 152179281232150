import styled from 'styled-components'
import Header from './header'
import About from './about'
import Capa from './capa'
import Plans from './plans'
import Depoimentos from './depoimentos'
import Faq from './faq'
import Footer from './footer'
import Product from './product'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    scroll-behavior: smooth;
`

export default function LandingPage() {
    return (
        <Page>
            <Header />
            <Capa />
            <About />
            <Product />
            <Plans />
            <Depoimentos />
            <Faq />
            <Footer />
        </Page>
    )
}
