import styled from 'styled-components'

interface ChatCounterProps {
    text: string
}

const StyledCounter = styled.span<{
    color: string
}>`
    position: absolute;
    top: 0;
    right: 80px;
    font-size: 12px;
    font-weight: 700;
    color: ${({ color }) => color};
`
export default function ChatCounter({ text }: ChatCounterProps) {
    const MAX_LENGTH = 1500
    const length = text.length
    const limitToYellow = length >= MAX_LENGTH * 0.8 && length < MAX_LENGTH
    const limitToRed = length >= MAX_LENGTH

    const color = limitToRed ? '#cc3300' : limitToYellow ? '#ffcc00' : '#339900'

    return (
        <StyledCounter color={color}>
            {length}/{MAX_LENGTH}
        </StyledCounter>
    )
}
