import styled from 'styled-components'
import { COLORS } from '../utils/colors'

const StyledButton = styled.button`
    display: flex;
    padding: 13.2px 13px;
    justify-content: center;
    cursor: pointer;
    position: relative;
    background-color: ${COLORS.branco};
    border-radius: 10px;
    width: 80%;
    max-width: 200px;
    border: 1px solid ${COLORS.cinzaEscuro};
    align-self: center;

    & img {
        position: absolute;
        width: 25px;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
    }

    & span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }
`

interface ButtonProps {
    SVGIcon: string
    onclick: () => any
    IconAltText: string
    text: string
    endText?: string
    disabled?: boolean
}

export default function Button({
    SVGIcon,
    IconAltText,
    onclick,
    text,
    endText,
    disabled = false,
}: ButtonProps) {
    function handleClick(e: any) {
        e.preventDefault()
        onclick()
    }
    return (
        <StyledButton disabled={disabled} onClick={handleClick}>
            <img src={SVGIcon} alt={IconAltText} />
            {text}
            <span>{endText}</span>
        </StyledButton>
    )
}
