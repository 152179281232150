import styled from 'styled-components'
import { COLORS } from '../utils/colors'

interface TooltipProps {
    component: any
    left: string
    top: string
    text: string
}

const StyledTooltip = styled.span<{ top: string; left: string }>`
    visibility: hidden;
    position: absolute;
    z-index: 10;
    font-size: 12px;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${COLORS.cinzaClaro};
    color: ${COLORS.cinzaEscuro};
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`
export default function TooltipWrapper({
    component,
    left,
    top,
    text,
}: TooltipProps) {
    return (
        <Wrapper>
            {component}
            <StyledTooltip top={top} left={left}>
                {text}
            </StyledTooltip>
        </Wrapper>
    )
}
