import styled from 'styled-components'
import { useEffect, useState } from 'react'
import Button from '../../../../globalComponents/ButtonPattern'
import Icon from '../../../../assets/email.svg'
import Check from '../../../../assets/check.svg'
import Warning from '../../../../assets/warning.svg'
import { AuthApi } from '../../../../services/AuthApi.service'
import useError from '../../../../globalComponents/useError'
import { useLoading } from '../../../../globalComponents/useLoading'
import FormField from '../../../../globalComponents/formField'
import logoTransparente from '../../../../assets/logoTransparente.png'
import { COLORS } from '../../../../utils/colors'
import Footer from '../../../../globalComponents/footer'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    h1 {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        white-space: pre-line;
        text-align: center;
    }
`
const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: ${COLORS.cinzaClaro};
    min-height: 100vh;
    position: relative;
`
const Error = styled.label`
    color: ${COLORS.vermelho};
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
`
const Logo = styled.img`
    width: 180px;
    margin-bottom: 30px;
    cursor: pointer;
`
const Form = styled.div`
    width: 300px;
    display: flex;
    /* gap: 30px; */
    flex-direction: column;
    align-items: center;
`
export default function ResetPassword() {
    const { ErrorComponent, showError } = useError()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { Spinner, isLoading, setIsLoading } = useLoading()
    const [buttonProps, setButtonProps] = useState({
        text: 'Enviar redefinição',
        icon: Icon,
    })
    const search = window.location.search
    const token = search.slice(7, search.length)
    async function handleSendTokenWithPasswordReset() {
        try {
            if (!password || password !== confirmPassword) {
                showError('Preencha corretamente os campos.', true)
            }
            await new AuthApi().sendTokenToResetPassword(token, password)
            window.location.href = '/login'
        } catch (error: any) {
            showError(error, true)
        }
    }
    async function handleSendPasswordLink() {
        if (!email) {
            return
        }
        setIsLoading(true)
        try {
            await new AuthApi().sendEmailForResetPassword(email)
            setButtonProps({
                text: `E-mail enviado`,
                icon: Check,
            })
            showError('E-mail enviado! Confira sua caixa de entrada.')
        } catch (error: any) {
            showError(error, true)
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <Page>
            <Logo
                onClick={() => (window.location.pathname = '/login')}
                src={logoTransparente}
            />
            <Container>
                <h1>
                    <img src={Warning} />
                    Redefina sua senha
                </h1>
                {!token ? (
                    <>
                        <p>
                            Insira o e-mail cadastrado para receber um link de
                            redefinição
                        </p>
                        <Form>
                            <FormField
                                submit={handleSendPasswordLink}
                                inputType="text"
                                state={email}
                                setState={setEmail}
                                label="Email"
                            />
                            <Button
                                disabled={isLoading}
                                onclick={handleSendPasswordLink}
                                text={buttonProps.text}
                                IconAltText="Enviar email"
                                SVGIcon={buttonProps.icon}
                            />
                        </Form>
                    </>
                ) : (
                    <>
                        <p>Digite sua nova senha:</p>
                        <Form>
                            <FormField
                                submit={handleSendTokenWithPasswordReset}
                                inputType="password"
                                state={password}
                                setState={setPassword}
                                label="Password"
                            />
                            <FormField
                                submit={handleSendTokenWithPasswordReset}
                                inputType="password"
                                state={confirmPassword}
                                setState={setConfirmPassword}
                                label="Digite novamente"
                            />
                            <Button
                                disabled={isLoading}
                                onclick={handleSendTokenWithPasswordReset}
                                text={'Redefinir senha'}
                                IconAltText="Redefinir senha"
                                SVGIcon={Icon}
                            />
                        </Form>
                    </>
                )}
                {isLoading && <Spinner />}
                {confirmPassword?.length > 0 &&
                    password !== confirmPassword && (
                        <Error>Os campos precisam ser iguais</Error>
                    )}
                {ErrorComponent}
            </Container>
            <Footer />
        </Page>
    )
}
