export function detectaCodigoMalicioso(codigo: string) {
    const regex = [
        /(<\s*script\s*>\s*)([\s\S]*?)(<\s*\/\s*script\s*>)/gi,
        /document\.write\((.*?)\)/gi,
        /(eval\s*\(\s*['"]([\s\S]*?)['"]\s*\))/gi,
        /(setTimeout\s*\(\s*function\s*\(\)\s*{[\s\S]*?}\s*,\s*\d+\s*\))/gi,
        /(setInterval\s*\(\s*function\s*\(\)\s*{[\s\S]*?}\s*,\s*\d+\s*\))/gi,
        /(window\.open\s*\(\s*['"]([\s\S]*?)['"]\s*\))/gi,
    ]

    for (let i = 0; i < regex.length; i++) {
        if (regex[i].test(codigo)) {
            return true
        }
    }

    return false
}
