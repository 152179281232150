import styled from 'styled-components'
import arrow from '../../assets/arrowDown.svg'
import { COLORS } from '../../utils/colors'
import { useState } from 'react'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
`
const Title = styled.h1`
    margin: 50px 0;
    font-size: 44px;
    font-weight: 700;
    max-width: 90vw;
    text-align: center;
`
const Arrow = styled.img<{ left?: boolean }>`
    transform: rotateZ(${({ left }) => (left ? '90deg' : '-90deg')});
    cursor: pointer;
    background-color: ${COLORS.cinzaDesbotado};
    border-radius: 50px;
    width: 30px;
    padding: 10px 5px;
`
const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 550px;
    height: 280px;
    max-width: 90vw;
    padding: 0 10px;
    border-radius: 30px;
    box-shadow: 1px 1px 8px 2px #c2c2c2;
    justify-content: space-around;
`
const InnerCard = styled.div`
    width: 400px;
    height: 300px;
    border-radius: 30px;
    max-width: 70vw;
    box-shadow: 1px 1px 8px 2px #c2c2c2;
    background-color: ${COLORS.branco};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 380px) {
        height: 380px;
    }
`
const Text = styled.p`
    text-align: center;
    width: 300px;
    max-width: 50vw;
`
const PaginationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    gap: 20px;
`
const Pagination = styled.div<{ selected: boolean; gap: string }>`
    border-radius: 100%;
    padding: 1px;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: ${({ selected }) =>
        selected ? COLORS.azul : COLORS.cinzaDesbotado};
`
const texts = [
    `Somos uma startup fundada em 2024 por um 
         grupo de entusiastas da tecnologia e do 
         Direito  (desenvolvedores, advogados,
          professores) que  integra, de modo pioneiro e disruptivo, inteligência artificial (IA) generativa & big data`,
    `A Schief.ai, de DNA digital, tem a missão de empoderar indivíduos e organizações a partir de assistentes jurídicos virtuais (AJVs) inteligentes, que executam tarefas intelectuais e transformam o modo de interagir com o conhecimento.`,
]

export default function About() {
    const [current, setCurrent] = useState(0)

    function next() {
        setCurrent((prev) => {
            if (prev >= texts.length - 1) {
                return 0
            }
            return prev + 1
        })
    }

    function back() {
        setCurrent((prev) => {
            if (prev <= 0) {
                return texts.length - 1
            }
            return prev - 1
        })
    }
    return (
        <Container id="sobre">
            <Title>Conheça a Schief.ai</Title>
            <CardWrapper>
                <Arrow left src={arrow} onClick={back} />
                <InnerCard>
                    <Text>{texts[current]}</Text>
                    <PaginationWrapper>
                        <Pagination
                            onClick={() => setCurrent(0)}
                            selected={current === 0}
                            gap="0px"
                        />
                        <Pagination
                            onClick={() => setCurrent(1)}
                            selected={current === 1}
                            gap="20px"
                        />
                        {/* <Pagination
                            onClick={() => setCurrent(2)}
                            selected={current === 2}
                            gap="40px"
                        /> */}
                    </PaginationWrapper>
                </InnerCard>
                <Arrow src={arrow} onClick={next} />
            </CardWrapper>
        </Container>
    )
}
