import styled from 'styled-components'
import { COLORS } from '../../utils/colors'
import mock1 from '../../assets/mock1.png'
import mock2 from '../../assets/gustavoschiefler.png'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 100%;

    @media (max-width: 910px) {
        padding: 50px 0;
    }
`
const Titulo = styled.h1`
    font-size: 44px;
    color: ${COLORS.azul};
    margin-bottom: 20px;
    text-align: center;
`
const Section = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

    @media (max-width: 910px) {
        flex-direction: column;
        justify-content: start;
        max-width: 90vw;
        margin-bottom: 0;
    }
`
const Foto = styled.img<{ margin: string }>`
    width: 350px;
    max-width: 90vw;
    margin: 0 ${({ margin }) => margin};
    border-radius: 100%;
    @media (max-width: 910px) {
        margin: 0 0 50px;
        order: 1;
    }
`
const Mock = styled.img<{ margin: string }>`
    width: 350px;
    max-width: 90vw;
    margin: 0 ${({ margin }) => margin};

    @media (max-width: 910px) {
        margin: 0 0 50px;
        order: 1;
    }
`
const Texto = styled.p`
    color: ${COLORS.azul};
    width: 400px;
    max-width: 90vw;
    @media (max-width: 910px) {
        text-align: center;
    }
`

const TextosWrapper = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 90vw;

    @media (max-width: 910px) {
        order: 2;

        width: auto;
        margin-bottom: 20px;
    }
`
const ImageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    font-size: 14px;
    font-weight: 500;
`

export default function Product() {
    return (
        <Container id="produto">
            <Titulo>Conheça o Nosso Produto</Titulo>
            <Section>
                <Mock src={mock1} margin="50px" />
                <TextosWrapper>
                    <Texto>
                        Nosso primeiro
                        <strong> assistente jurídico virtual (AJV)</strong> é um
                        <i> chatbot</i> especializado em
                        <strong>
                            {' '}
                            licitações públicas e contratos administrativos.{' '}
                        </strong>
                        Ele integra o que há de mais avançado em IA generativa
                        (usa o motor do <strong>GPT 4.0</strong>) com a
                        curadoria especializada de um{' '}
                        <i>
                            <strong>Big Data</strong>
                        </i>{' '}
                        sobre o tema (o combustível!)
                    </Texto>
                    <Texto>
                        A escolha não poderia ser diferente: é a área jurídica
                        de especialidade dos fundadores da Schief.ai, a exemplo
                        do advogado, professor e{' '}
                        <strong>Doutor (USP) Gustavo Schiefler</strong>, com
                        mais de 12 anos de experiência na área.
                    </Texto>
                </TextosWrapper>
            </Section>
            <Section>
                <TextosWrapper>
                    <Texto>
                        A nossa ferramenta está na{' '}
                        <strong>fronteira tecnológica</strong>. Ela responde
                        <strong> automaticamente</strong>, e de modo{' '}
                        <strong>assertivo</strong>, a{' '}
                        <strong>questões técnicas</strong> sobre contratações
                        públicas e apresenta respostas com base em{' '}
                        <strong>normas jurídicas</strong>, em manuais de{' '}
                        <strong>boas práticas</strong> e em{' '}
                        <strong>jurisprudência</strong> sobre o assunto.
                    </Texto>
                    <Texto>
                        É uma <strong>tecnologia disruptiva</strong>: o
                        equivalente a uma
                        <strong> calculadora </strong> para os operadores do
                        <strong> Direito</strong>!
                    </Texto>
                </TextosWrapper>
                <ImageTextWrapper>
                    <Foto src={mock2} margin="50px" />
                    <p>CEO - Responsável Técnico pela Schief.ai</p>
                </ImageTextWrapper>
            </Section>
        </Container>
    )
}
