import LeftBar from './components/leftBar'
import Chat from './components/chat'
import RightBar from './components/rightBar'
import styled from 'styled-components'
import HistoryProvider from '../../contexts/history'
import { CookiesService } from '../../services/Cookie.service'
import { useEffect } from 'react'
import ReactGA from 'react-ga4';

const Page = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

export default function Home() {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home"
    })

    useEffect(() => {
        const cookieService = new CookiesService()
        const flag = cookieService.getFlag()
        let url

        if (flag?.includes('plano1')) {
            if (flag.includes('mensal')) {
                url = process.env.REACT_APP_URL_MENSAL_PLANO1
            } else {
                url = process.env.REACT_APP_URL_ANUAL_PLANO1
            }
        } else if (flag?.includes('plano2')) {
            if (flag.includes('mensal')) {
                url = process.env.REACT_APP_URL_MENSAL_PLANO2
            } else {
                url = process.env.REACT_APP_URL_ANUAL_PLANO2
            }
        }
        if (url) {
            window.location.href = url!
            cookieService.removeFlag()
        }
    }, [])

    return (
        <Page>
            <HistoryProvider>
                <LeftBar></LeftBar>
                <Chat></Chat>
                {/* <RightBar></RightBar> */}
            </HistoryProvider>
        </Page>
    )
}
